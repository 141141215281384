@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
*,
*::before,
*::after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

iframe {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

ul,
ol {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

p, h1, h2, h3, h4, h5, h6, label {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

a:focus, a:hover {
  text-decoration: none;
}

button,
input[type="submit"],
input[type="file"],
input[type="button"] {
  border-radius: 0;
  color: inherit;
  padding: 0;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}

input,
button,
textarea,
select {
  letter-spacing: inherit;
  font-family: inherit;
  font-size: inherit;
  appearance: none;
  border: none;

}

button {
  background: none;
}

html,
body,
#root {
  height: 100%;
}

body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  line-height: 1.5;
  -webkit-tap-highlight-color: transparent;
}
